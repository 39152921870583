.glow {
  -webkit-box-shadow: 0px 0px 5px 2px #febd16;
  -moz-box-shadow: 0px 0px 5px 2px #febd16;
  box-shadow: 0px 0px 5px 2px #febd16;
}

.more-glow {
  -webkit-box-shadow: 0px 0px 10px 3px #febd16;
  -moz-box-shadow: 0px 0px 10px 3px #febd16;
  box-shadow: 0px 0px 10px 3px #febd16;
}
