#form-checkout {
  display: flex;
  flex-direction: column;
  max-width: 600px;
}

.container {
  width: 200px;
  height: 30px;
  display: inline-block;
  border: 1px solid rgb(118, 118, 118);
  border-radius: 2px;
  padding: 1px 2px;
}

.icon {
  @apply w-12 h-12 xl:w-16 xl:h-16 p-2 xl:p-3 mr-1 xl:mr-2 bg-white text-dewalt rounded-full border-2 border-dewalt;
}
