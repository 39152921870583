@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@font-face {
  font-family: 'Open Sans Condensed';
  src: url('./assets/fonts/OpenSans_Condensed-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('./assets/fonts/OpenSans_Condensed-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('./assets/fonts/OpenSans_Condensed-ExtraBold.ttf');
  font-weight: 800;
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('./assets/fonts/OpenSans_Condensed-ExtraBoldItalic.ttf');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('./assets/fonts/OpenSans_Condensed-Italic.ttf');
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('./assets/fonts/OpenSans_Condensed-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('./assets/fonts/OpenSans_Condensed-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('./assets/fonts/OpenSans_Condensed-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('./assets/fonts/OpenSans_Condensed-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('./assets/fonts/OpenSans_Condensed-Regular.ttf');
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('./assets/fonts/OpenSans_Condensed-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('./assets/fonts/OpenSans_Condensed-SemiBoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
}

:root {
  --topbar-height: 68px;
  --content-max-width: 1280px;
  color-scheme: only light;
}

body {
  font-family: 'Proxima Nova', -apple-system, Roboto, Arial, sans-serif;
  overflow-y: scroll;
}

.cho-container {
  @apply w-full h-full;
}
.mercadopago-button {
  @apply w-full h-full;
  background: #febd16 !important;
  font-weight: 600 !important;
  font-size: 1rem !important;
  color: black !important;
  transition: all 150ms;
}

.mercadopago-button:hover {
  background: black !important;
  color: #febd16 !important;
}

.gradient-dewalt-ttb {
  background: linear-gradient(180deg, rgba(254, 189, 22, 1) 0%, rgba(255, 255, 255, 0) 92%);
}
