@keyframes yaguarete {
  0% {
    transform: scale(50%);
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    transform: scale(100%);
    opacity: 0;
  }
}
